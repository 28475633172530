<template>
    <div id="app" class="w-full h-full bg-gray-200">
        <div v-if="isAuthenticated" class="h-full">
            <router-view />
        </div>

        <div v-if="!isAuthenticated" class="h-full">
            <router-view name="index" />
        </div>

        <app-notifications group="flash" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'App',

    computed: {
        ...mapGetters('auth', ['isAuthenticated']),
    },
};
</script>
